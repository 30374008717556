import { ConditionVar } from "gql";

export const KNOWN_VAR_OPERATORS = [
  "BOOLEAN",
  "STRING",
  "UUID",
  "ENUM",
  "INTEGER",
  "LONG",
  "DATETIME",
  "OFFSETDATETIME",
  "LOCALDATETIME",
  "LOCALDATE",
] as const;

export type KnownVarOperator = typeof KNOWN_VAR_OPERATORS[number];

export interface EnumConditionVar extends ConditionVar {
  type: "ENUM";
  values: string[];
}

export interface TypedConditionVar extends ConditionVar {
  type: KnownVarOperator;
  values: null;
}

export type KnownConditionVar = EnumConditionVar | TypedConditionVar;

export const enum STATUS {
  PENDING = "pending",
  READY = "ready",
}
